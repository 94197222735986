import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import config from '../config'
import {
	selectNewCropStatusCount,
	selectStoreId,
} from '../features/app/selectors'
import { selectUsername, selectUserRole } from '../features/auth/selectors'
import dialogActions from '../features/dialog/actions'
import { selectExam } from '../features/exams/selectors'
import renewalActions from '../features/renewal/actions'
import { selectPendingAsessments } from '../features/renewal/selectors'
import { selectStore } from '../features/stores/selectors'
import {
	selectIsAdmin,
	selectIsDoctor,
	selectIsDoctorAdmin,
	selectIsFrontDesk,
	selectIsInStoreUser,
	selectIsLocalTechnician,
	selectIsPracticeManager,
	selectIsRemoteDoctor,
	selectPendingExamId,
	selectRenewalPrescriptionEnabled,
	selectTeloEnabled,
	selectUser,
	selectUserCanOpenEPrescribing,
	selectUserCanOpenMIPS,
	selectUserLocation,
	selectUserStores,
} from '../features/users/selectors'
import useEclipsMetadata from '../hooks/useEclipsMetadata'
import { useSyncDataAssessments } from '../hooks/useSyncData'
import { getUserDashboard } from '../libs/auth'
import { isGeminiRegion } from '../libs/region'
import { firstLetterCapital, pxToRem } from '../libs/style'
import { isTodayZoned } from '../libs/time'
import { userRoleAndPrivileges } from '../libs/users'
import { Privilege, Role } from '../model/users'
import { useCheckCurrentPage } from '../routing/teloRouterUtils'
import { useTeloDispatch, useTeloSelector } from '../store'
import Badge from '../styleguide/Badge'
import Button from '../styleguide/buttons/Button'
import DropdownMenu from '../styleguide/DopdownMenu'
import UserIcon from '../styleguide/icons/UserIcon'
import LinkNoStyle from '../styleguide/LinkNoSyle'
import theme from '../styleguide/theme'
import Capitalize from '../styleguide/typography/Capitalize'
import { EPrescribingMenuItem } from './EPrescribingMenuItem'
import Dialog from '../styleguide/dialog/Dialog'
import { DialogContent, DialogTitle } from '../styleguide/dialog'
import Grid from '../styleguide/Grid'
import { IMAGES } from '../utils/assetRegistry'
import Box from '../styleguide/Box'

const DropdownMenuStyled = styled(DropdownMenu)`
	z-index: 1071;
`

const DashboardLink = styled(LinkNoStyle)``

const StoreName = styled.span`
	font-size: 0.75rem;
	font-style: normal;
	color: ${theme.palette.grey[700]};
`

const LogoutButton = styled(Button)`
	padding: 0;
	text-align: left;
	color: inherit;

	.MuiButton-label {
		display: inline;
	}
`

const AboutButton = styled(Button)`
	padding: 0;
	text-align: left;
`

const RoleLabel = styled.div`
	font-size: 0.75rem;
	font-style: normal;
	color: ${theme.palette.grey[700]};
`

const MenuHeader = styled.div`
	border-radius: ${theme.spacing(1.5)} ${theme.spacing(1.5)} 0 0;
	padding: ${theme.spacing(2.5)} ${theme.spacing(2)} ${theme.spacing(2)};
	background: var(
		--background-brand-muted,
		linear-gradient(231deg, #f4e1ef -17.3%, #87bce1 115.47%)
	);
	display: flex;
	flex-direction: column;
	gap: ${theme.spacing(0.5)};
`

const MenuHeaderTitle = styled.span`
	font-weight: 500;
	font-size: ${pxToRem(16)}rem;
	color: ${theme.palette.primary.main};
`

const MenuHeaderSubtitle = styled.span`
	font-size: ${pxToRem(12)}rem;
	font-weight: 400;
	color: ${theme.palette.cyan.dark};
`

const EPrescribingItemWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const StyledBadge = styled(Badge)`
	margin-right: ${theme.spacing(1)};

	.MuiBadge-badge {
		color: ${theme.palette.secondary.contrastText};
		background-color: ${theme.palette.secondary.main};
		font-weight: 500;
	}
`

const Logo = styled.img<{ visionx: boolean }>`
	width: ${props => (props.visionx === true ? '70%' : '100%')};
	height: auto;
	display: block;
	grid-column: 2;
	place-self: center center;
`

const ParagraphTitle = styled.p`
	font-size: 14px;
	font-weight: 500;
	color: #5c6b82;
`

const Paragraph = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 19.6px;
	letter-spacing: -0.03em;
	text-align: left;
`

const ContainerLogo = styled.div`
	padding: 32px 24px 32px 24px;
	background-color: #edf3fd;
`

const AboutDialogContent = styled(DialogContent)`
	padding: 0px !important;
`

const EPrescribingItem: React.FC<{
	storeId: string
	username: string
	counter: number
	onItemClick: (storeId: string, username: string) => void
}> = ({ storeId, username, counter, onItemClick }) => {
	const { t } = useTranslation()

	return (
		<EPrescribingItemWrapper>
			{t('app.ePrescribingMenuItem')}
			<StyledBadge
				badgeContent={counter.toString()}
				onClick={() => onItemClick(storeId, username)}
			/>
		</EPrescribingItemWrapper>
	)
}

const SwitchDashboardLink: React.FC<{
	pendingExams?: string
	goToRenewalApp: boolean
	roles: Role
	storeId: string
	itemKey: string
	itemsRef: React.MutableRefObject<{
		[key: string]: HTMLButtonElement | HTMLDivElement | null
	}>
}> = ({
	pendingExams,
	goToRenewalApp,
	roles,
	storeId,
	itemsRef,
	itemKey,
}) => {
	const { t } = useTranslation()
	const label = firstLetterCapital(
		t(goToRenewalApp ? 'app.goToRenewalDashboard' : 'app.goToDashboard'),
	)
	const link = goToRenewalApp
		? '/doctor/renewal/dashboard'
		: getUserDashboard(roles, storeId)
	return (
		<DashboardLink
			to={link}
			ref={el => {
				itemsRef.current[itemKey] = el
			}}
		>
			{pendingExams ? (
				<Badge badgeContent={pendingExams} color="error">
					{label}
				</Badge>
			) : (
				label
			)}
		</DashboardLink>
	)
}

const ProfileMenu = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const user = useTeloSelector(selectUser)
	const isDoctor = useTeloSelector(selectIsDoctor)
	const isTechnician = useTeloSelector(selectIsLocalTechnician)
	const isFrontDesk = useTeloSelector(selectIsFrontDesk)
	const isRemote = useTeloSelector(selectIsRemoteDoctor)
	const mainRole = useTeloSelector(selectUserRole)
	const username = useTeloSelector(selectUsername)
	const roles = useTeloSelector(selectUserRole)
	const isInStoreUser = useTeloSelector(selectIsInStoreUser)
	const storeId = useTeloSelector(selectStoreId)
	const store = useTeloSelector(selectStore)
	const userStores = useTeloSelector(selectUserStores)
	const pendingExamIdTelo = useTeloSelector(selectPendingExamId(username))
	const pendingExamTelo = useTeloSelector(selectExam(pendingExamIdTelo || ''))
	const eprescribingEnabled = useTeloSelector(selectUserCanOpenEPrescribing)
	const mipsEnabled = useTeloSelector(selectUserCanOpenMIPS)

	const {
		isRenewalApp,
		isAdminPage,
		isProfilePage,
		isStoreSettingsPage,
		isStoreSelectionPage,
	} = useCheckCurrentPage()
	const itemsRef = useRef<{
		[key: string]: HTMLDivElement | HTMLButtonElement | null
	}>({})

	const userLocation = useTeloSelector(selectUserLocation)
	const privileges = userRoleAndPrivileges(user)
	const isPracticeManager = useTeloSelector(selectIsPracticeManager)
	const isAdmin = useTeloSelector(selectIsAdmin)
	const eclipsMetadata = useEclipsMetadata()
	const isRPEnabled = useTeloSelector(selectRenewalPrescriptionEnabled)

	const isAppInsideEclips = eclipsMetadata !== undefined
	const pendingExamTeloToShow =
		pendingExamTelo &&
		isTodayZoned(pendingExamTelo.createdAt, pendingExamTelo.store.timezone)
			? '1'
			: ''

	const newCropCounter = useTeloSelector(selectNewCropStatusCount)
	const disablePrAssessmentFetch =
		isGeminiRegion === true ||
		isAppInsideEclips === true ||
		roles !== 'Doctor' ||
		!isRPEnabled

	const fetchAssessmentsData = useCallback(async () => {
		dispatch(renewalActions.fetchAssessments())
	}, [dispatch])

	useEffect(() => {
		if (
			disablePrAssessmentFetch === false &&
			isDoctor === true &&
			isRPEnabled
		) {
			fetchAssessmentsData()
		}
	}, [fetchAssessmentsData, isDoctor, isRPEnabled, disablePrAssessmentFetch])

	useSyncDataAssessments(disablePrAssessmentFetch)

	const showMipsButton =
		(!isDoctor && isPracticeManager) || isAdmin || mipsEnabled

	const shouldRenderEPrescribingItem =
		isRemote === false &&
		storeId !== undefined &&
		storeId.length > 0 &&
		eprescribingEnabled === true

	const mainRoleTranslationPath = mainRole ? `renewal.roles.${mainRole}` : ''

	const pendingAssessments = useTeloSelector(selectPendingAsessments)

	const pendingAssessmentsString = pendingAssessments.length
		? pendingAssessments.length.toString()
		: ''
	const isDoctorAdmin = useTeloSelector(selectIsDoctorAdmin(username))
	const pendingAssessmentToShow = isDoctorAdmin ? '' : pendingAssessmentsString

	const isTeloEnabled = useTeloSelector(selectTeloEnabled)

	const isControlPanelEnabled =
		(privileges || []).some(r =>
			(
				[
					'TechnicalAdmin',
					'GlobalAdmin',
					'Doctor',
					'StoreManager',
					'SetupManager',
					'ReportManager',
				] as (Role | Privilege)[]
			).includes(r),
		) || isPracticeManager

	const inStoreCount = userStores.length

	const getPendingExamBadgeContent = useCallback(() => {
		let total = 0

		if (!isRenewalApp && isRPEnabled && pendingAssessmentToShow !== '') {
			total += parseInt(pendingAssessmentToShow)
		}

		if (isRenewalApp && isTeloEnabled && pendingExamTeloToShow === '1') {
			total += parseInt(pendingExamTeloToShow)
		}

		if (shouldRenderEPrescribingItem) {
			total += newCropCounter
		}

		return total > 0 ? total.toString() : ''
	}, [
		pendingExamTeloToShow,
		isRPEnabled,
		isRenewalApp,
		isTeloEnabled,
		newCropCounter,
		pendingAssessmentToShow,
		shouldRenderEPrescribingItem,
	])

	const getSwitchDashboardLink = (
		isTeloEnabled: boolean,
		isRPEnabled: boolean,
		badgeCount?: string,
	) => {
		const isRenewalAppKey = `2-${Date.now}`
		const isRPEnabledKey = `1-${Date.now}`

		if (isRenewalApp && isTeloEnabled) {
			return [
				<SwitchDashboardLink
					key={isRenewalAppKey}
					pendingExams={badgeCount}
					goToRenewalApp={false}
					roles={roles}
					storeId={storeId}
					itemsRef={itemsRef}
					itemKey={isRenewalAppKey}
				/>,
			]
		}

		if (!isRenewalApp && isRPEnabled) {
			return [
				<SwitchDashboardLink
					key={isRPEnabledKey}
					pendingExams={badgeCount}
					goToRenewalApp={true}
					roles={roles}
					storeId={storeId}
					itemsRef={itemsRef}
					itemKey={isRPEnabledKey}
				/>,
			]
		}

		return []
	}

	const pendingExamBadgeContent = getPendingExamBadgeContent()
	const switchDashboardLink = getSwitchDashboardLink(
		isTeloEnabled,
		isRPEnabled,
		pendingExamBadgeContent,
	)

	const menuItemsEclips: React.JSX.Element[] = ([] as React.JSX.Element[])
		.concat(
			shouldRenderEPrescribingItem
				? [<EPrescribingMenuItem key="EPrescribingItem" />]
				: [],
		)
		.concat(
			(isDoctor || isTechnician || isFrontDesk) &&
				(isAdminPage || isProfilePage)
				? [
						<DashboardLink
							to={getUserDashboard(roles, storeId)}
							key="goToDashboard"
						>
							{t('app.goToDashboard')}
						</DashboardLink>,
				  ]
				: [],
		)
		.concat(
			isControlPanelEnabled && (!isAdminPage || isStoreSettingsPage)
				? [
						<DashboardLink to="/admin" key="goToControlPanel">
							{t('app.goToControlPanel')}
						</DashboardLink>,
				  ]
				: [],
		)
		.concat(
			!isProfilePage
				? [
						<LinkNoStyle to="/profile" key="profile">
							<Capitalize>{t('app.profile')}</Capitalize>
						</LinkNoStyle>,
				  ]
				: [],
		)
		.concat(
			isDoctor && isInStoreUser
				? [
						<LinkNoStyle to="/room-device" key="room-device">
							<span>{t('app.roomDevice.changeRoomDevice')}</span>
						</LinkNoStyle>,
				  ]
				: [],
		)
		.concat(
			showMipsButton
				? [
						<LogoutButton
							key="logout"
							variant="text"
							onClick={() => {
								window.open(config.mips.url, '_blank')
							}}
						>
							<span>{t('users.mips')}</span>
						</LogoutButton>,
				  ]
				: [],
		)

	const menuItemsConnect: React.JSX.Element[] = [
		<MenuHeader key="menuItemsConnect" className="profile-menu-header">
			{user && user.name && user.surname && (
				<MenuHeaderTitle>
					{user.name} {user.surname}
				</MenuHeaderTitle>
			)}
			<MenuHeaderSubtitle>
				{firstLetterCapital(t(mainRoleTranslationPath))}{' '}
				{userLocation ? `(${userLocation})` : ''}
			</MenuHeaderSubtitle>
		</MenuHeader>,
	]
		.concat(
			shouldRenderEPrescribingItem
				? [<EPrescribingMenuItem key="EPrescribingItem" />]
				: [],
		)
		.concat(
			isControlPanelEnabled && isDoctor && (isAdminPage || isProfilePage)
				? [
						<DashboardLink
							to={getUserDashboard(roles, storeId)}
							key="goToDashboard"
							ref={el => (itemsRef.current['goToDashboard'] = el)}
						>
							{t('app.goToDashboard')}
						</DashboardLink>,
				  ]
				: [],
		)
		.concat(
			isControlPanelEnabled && (!isAdminPage || isStoreSettingsPage)
				? [
						<DashboardLink
							to="/admin?as=0"
							key="goToControlPanel"
							ref={el => (itemsRef.current['goToControlPanel'] = el)}
						>
							{t('app.goToControlPanel')}
						</DashboardLink>,
				  ]
				: [],
		)
		.concat(isDoctor ? switchDashboardLink : [])
		.concat(
			!isProfilePage
				? [
						<LinkNoStyle
							to="/profile"
							key="profile"
							ref={el => (itemsRef.current['profile'] = el)}
						>
							<Capitalize>{t('app.profile')}</Capitalize>
						</LinkNoStyle>,
				  ]
				: [],
		)
		.concat(
			isInStoreUser && inStoreCount > 1 && !isStoreSelectionPage && !isAdmin
				? [
						<LinkNoStyle
							to="/store-selection"
							key="store-selection"
							ref={el => (itemsRef.current['store-selection'] = el)}
						>
							<span>{t('app.changeSelectedStore')}</span>
							<br />
							{store ? <StoreName>{store.name}</StoreName> : null}
						</LinkNoStyle>,
				  ]
				: [],
		)
		.concat(
			isDoctor && isInStoreUser
				? [
						<LinkNoStyle
							to="/room-device"
							key="room-device"
							ref={el => (itemsRef.current['room-device'] = el)}
						>
							<span>{t('app.roomDevice.changeRoomDevice')}</span>
						</LinkNoStyle>,
				  ]
				: [],
		)
		.concat(
			showMipsButton
				? [
						<LogoutButton
							key="logout"
							variant="text"
							onClick={() => {
								window.open(config.mips.url, '_blank')
							}}
							ref={el => (itemsRef.current['logout'] = el)}
						>
							<span>{t('users.mips')}</span>
						</LogoutButton>,
				  ]
				: [],
		)
		.concat([
			<AboutButton
				key="about"
				variant="text"
				onClick={() => {
					setShowAboutModal(true)
				}}
				ref={el => (itemsRef.current['about'] = el)}
			>
				{t('aboutDialog.about')}
			</AboutButton>,
		])
		.concat([
			<LogoutButton
				key="signOut"
				className="profile-menu-logout"
				variant="text"
				onClick={() => {
					dispatch(dialogActions.openDialog({ type: 'logout' }))
				}}
				ref={el => (itemsRef.current['signOut'] = el)}
			>
				{t('login.signOut')}
			</LogoutButton>,
		])

	const [showAboutModal, setShowAboutModal] = useState(false)
	const logoSrc =
		config.region === 'GEMINI'
			? IMAGES.VISIONX_LOGO_URL
			: IMAGES.CONNECT_LOGO_URL
	const items = isAppInsideEclips ? menuItemsEclips : menuItemsConnect

	return (
		<div className="ott-ltDashboard-ProfileMenu">
			<DropdownMenuStyled
				label={username}
				badgeContent={pendingExamBadgeContent}
				badgeColor="error"
				startIcon={<UserIcon />}
				menuItems={items}
				refs={itemsRef}
			/>
			{!isAppInsideEclips ? (
				<RoleLabel>
					{firstLetterCapital(t(mainRoleTranslationPath))}{' '}
					{userLocation ? `(${userLocation})` : ''}
				</RoleLabel>
			) : null}
			<Dialog open={showAboutModal} fullWidth maxWidth="md">
				<DialogTitle
					onClose={() => {
						setShowAboutModal(false)
					}}
				>
					{t('aboutDialog.about')}
				</DialogTitle>
				<AboutDialogContent>
					<>
						<ContainerLogo>
							<Logo src={logoSrc} visionx={config.region === 'GEMINI'} />
						</ContainerLogo>
						<Grid
							container
							direction="row"
							alignItems="left"
							spacing={3}
							fontSize={12}
							padding={'24px'}
						>
							<Grid item xs={3}>
								<ParagraphTitle>{t('aboutDialog.version')}</ParagraphTitle>
							</Grid>
							<Grid item xs={9}>
								<Paragraph>{process.env.REACT_APP_VERSION}</Paragraph>
							</Grid>
							<Grid item xs={3}>
								<ParagraphTitle>{t('aboutDialog.copyright')}</ParagraphTitle>
							</Grid>
							<Grid item xs={9}>
								<Paragraph>
									{t('aboutDialog.copyrightSymbol')} 2013-
									{new Date().getFullYear()}
								</Paragraph>
								<Paragraph>{t('aboutDialog.visionWeb')}</Paragraph>
								<Paragraph>{t('aboutDialog.rights')}</Paragraph>
							</Grid>
							<Grid item xs={3}>
								<ParagraphTitle>
									{t('aboutDialog.warning.warningLabel')}
								</ParagraphTitle>
							</Grid>
							<Grid item xs={9}>
								<Paragraph>{t('aboutDialog.warning.warningText')}</Paragraph>
							</Grid>
							<Grid item xs={3}>
								<ParagraphTitle>
									{t('aboutDialog.trademark.label')}
								</ParagraphTitle>
							</Grid>
							<Grid item xs={9}>
								<Paragraph>
									{t('aboutDialog.trademark.cpt', {
										year: new Date().getFullYear() - 1,
									})}
								</Paragraph>
								<Box mt={3}>
									<Paragraph>
										{t('aboutDialog.trademark.USGovernment')}
									</Paragraph>
								</Box>
								<Box mt={3}>
									<Paragraph>
										{t('aboutDialog.trademark.responsibility')}
									</Paragraph>
								</Box>
							</Grid>
						</Grid>
					</>
				</AboutDialogContent>
			</Dialog>
		</div>
	)
}

export default ProfileMenu
