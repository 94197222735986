import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { prefixWithURL } from './utils/assetUtils'

const options = {
	returnNull: false,
	returnDetails: false,
	returnObjects: false,
	supportedLngs: ['en-US', 'fr', 'fr-FR', 'es', 'es-ES', 'ca', 'ca-ES', 'it', 'it-IT'],
	load: 'currentOnly',
	fallbackLng: 'en-US',
	order: ['navigator', 'localStorage'],
	debug: false,
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	backend: {
		crossDomain: true,
		loadPath: prefixWithURL(`/locales/{{lng}}/{{ns}}.json?t=${Date.now()}`),
	},
	saveMissing: true,
	missingKeyHandler: (lng, ns, key, fallbackValue) => {
		console.warn(`[connect] key "${key}" is missing`, {
			namespace: ns,
			language: lng,
			fallbackValue,
		})
	},
}

export const i18nLoadCompleted = i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init(options)
