import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
	SensitivityFlag,
	SesAttachment,
	SesComposeAttachmentEHRByExam,
	SesComposeMessage,
	SesContact_db,
	SesMessageHeader,
	MessagesToAttachEntry,
} from '../../model/ses'

type SesView =
	| 'none'
	| 'list-inbox'
	| 'list'
	| 'message'
	| 'composer'
	| 'contacts'
	| 'contact'
	| 'composer-contacts-to'
	| 'composer-contacts-cc'
	| 'composer-contacts-search-to'
	| 'composer-contacts-search-cc'
	| 'composer-contacts-add'
	| 'composer-attach-ehr'
	| 'composer-sent-confirmation'
	| 'composer-sent-error'

export const getComposeMessageInitialState = (): SesComposeMessage => {
	return {
		toAddress: '',
		ccAddresses: '',
		subject: '',
		sensitivity: 'Normal' as SensitivityFlag,
		bodyHtml: '',
		attachments: [] as SesAttachment[],
		attachmentsEHR: {} as SesComposeAttachmentEHRByExam,
	}
}

const initialState = {
	showView: 'none' as SesView,
	viewHistory: [] as SesView[],
	selectedMessageHeader: null as null | SesMessageHeader,
	selectedContact: null as null | SesContact_db,
	composeMessage: getComposeMessageInitialState(),
	autoCloseTimeout: null as NodeJS.Timeout | null,
	messagesToAttach: JSON.parse(
		localStorage.getItem('messagesToAttach') ?? '[]',
	),
}

export const slice = createSlice({
	name: 'ses',
	initialState,
	reducers: {
		setShowView: (
			state,
			{
				payload,
			}: PayloadAction<{
				name: SesView
				messageHeader?: SesMessageHeader
				contact?: SesContact_db
				keepState?: boolean
			}>,
		) => {
			if (state.showView !== 'none' && payload.name !== 'none') {
				// Max history stack of 5 views
				state.viewHistory = state.viewHistory.slice(-5).concat([state.showView])
			}
			if (payload.name === 'none') {
				state.viewHistory = []
				state.composeMessage = getComposeMessageInitialState()
				if (state.autoCloseTimeout !== null) {
					clearTimeout(state.autoCloseTimeout)
					state.autoCloseTimeout = null
				}
			}
			state.showView = payload.name
			state.selectedContact =
				(payload.keepState ? state.selectedContact : payload.contact) || null
			state.selectedMessageHeader =
				(payload.keepState
					? state.selectedMessageHeader
					: payload.messageHeader) || null
		},
		showPrevView: (
			state,
			{ payload }: PayloadAction<{ skip?: number } | undefined>,
		) => {
			const viewHistory = state.viewHistory.slice(
				0,
				payload?.skip ? -payload.skip : undefined,
			)
			const showView = viewHistory.pop() || 'none'
			state.viewHistory = viewHistory
			if (state.showView === 'composer') {
				state.composeMessage = getComposeMessageInitialState()
			}
			state.showView = showView
		},
		setComposeMessage: (
			state,
			{ payload }: PayloadAction<Partial<SesComposeMessage> | null>,
		) => {
			if (payload === null) {
				state.composeMessage = getComposeMessageInitialState()
			} else {
				state.composeMessage = { ...state.composeMessage, ...payload }
			}
		},
		setAutocloseTimeout: (
			state,
			{ payload }: PayloadAction<NodeJS.Timeout | null>,
		) => {
			state.autoCloseTimeout = payload
		},
		setMessageToAttach: (
			state,
			{ payload }: PayloadAction<MessagesToAttachEntry>,
		) => {
			const { orderId, resultId, messageIds } = payload
			const existingEntry = state.messagesToAttach.find(
				(entry: MessagesToAttachEntry) =>
					entry.orderId === orderId && entry.resultId === resultId,
			)

			if (existingEntry) {
				existingEntry.messageIds = [...existingEntry.messageIds, ...messageIds]
			} else {
				state.messagesToAttach.push({
					orderId,
					resultId,
					messageIds,
				})
			}

			localStorage.setItem(
				'messagesToAttach',
				JSON.stringify(state.messagesToAttach),
			)
		},
		deleteMessagesFromAttach: (
			state,
			{ payload }: PayloadAction<{ orderId: string; messageIds: string[] }>,
		) => {
			const existingEntry = state.messagesToAttach.find(
				(entry: MessagesToAttachEntry) => entry.orderId === payload.orderId,
			)

			if (existingEntry) {
				existingEntry.messageIds = existingEntry.messageIds.filter(
					(id: string) => !payload.messageIds.includes(id),
				)

				if (existingEntry.messageIds.length === 0) {
					state.messagesToAttach = state.messagesToAttach.filter(
						(entry: MessagesToAttachEntry) => entry.orderId !== payload.orderId,
					)
				}
			}

			localStorage.setItem(
				'messagesToAttach',
				JSON.stringify(state.messagesToAttach),
			)
		},
		clearAllMessagesFromAttach: state => {
			state.messagesToAttach = []
			localStorage.setItem(
				'messagesToAttach',
				JSON.stringify(state.messagesToAttach),
			)
		},
	},
})

export default slice.reducer
