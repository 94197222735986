import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTeloDispatch, useTeloSelector } from '../../store'
import { selectIsDebugMode } from '../../features/app/selectors'
import appActions from '../../features/app/actions'
import Switch from '../../styleguide/Switch'

const DEBUG_MODE_LANG = 'cimode'
const LANG_KEY = 'i18nextLng'

const DebugModeSwitch: React.FC = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useTeloDispatch()
	const isDebugMode = useTeloSelector(selectIsDebugMode)

	const initialLanguage =
		localStorage.getItem(LANG_KEY) || i18n.languages[0] || 'en'

	const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage)

	const handleChange = (checked: boolean) => {
		if (checked) {
			// Save current language
			setSelectedLanguage(i18n.language)
			i18n.changeLanguage(DEBUG_MODE_LANG)

			dispatch(appActions.setIsDebugMode(true))
		} else {
			// Restore the saved language
			i18n.changeLanguage(selectedLanguage)

			dispatch(appActions.setIsDebugMode(false))
		}
	}

	return (
		<Switch
			checked={isDebugMode}
			labelPlacement="start"
			label={t('app.debug')}
			onChange={(_, checked) => handleChange(checked)}
		/>
	)
}

export { DebugModeSwitch }
