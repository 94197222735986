import { omit } from 'lodash'
import { isDevelopment, isQA, isShowroom, isTest } from '../../libs/utils'

import { Exam } from '../../model/exam'
import { RootState } from '../../model/model'
import { getIntersessionStoreId } from '../../libs/intersession'

export const selectAppLang = (state: RootState) => state.app.lang

export const selectStoreId = (state: RootState) =>
	state.app.storeId || getIntersessionStoreId() || ''

export const selectStorePracticeExternalId = (state: RootState) =>
	state.app.practiceExternalId

export const selectInStore = (state: RootState) => state.app.inStore

export const selectSessionId = (state: RootState) => state.app.sessionId

export const selectOnline = (state: RootState) => state.app.online

export const selectNewCropStatusCount = (state: RootState) =>
	state.app.newCropStatusCount

// Currently not used
export const selectAppStateToLog = (state: RootState) => {
	const sliceToExclude = ['prescriptions']
	return Object.entries(state)
		.filter(([key]) => !sliceToExclude.includes(key))
		.map(([key, value]) => {
			if (key === 'cockpit') {
				return [key, omit(value, 'screenshareFrame')]
			}

			if (key === 'exams') {
				const exams: Exam[] = Object.values(value)
				const strippedExams = exams.map((exam: Exam) => omit(exam, 'preTest'))
				return [key, strippedExams]
			}

			return [key, value]
		})
		.reduce((result, [key, value]) => {
			result[key] = value
			return result
		}, {} as Record<string, any>)
}

export const selectIsRoomDeviceAvailable = (state: RootState) =>
	state.app.roomDeviceAvailable

export const selectIsOTTMode = (state: RootState) => {
	const envEnabled = isShowroom || isDevelopment || isTest || isQA

	if (envEnabled === false) {
		return false
	}

	const enabled =
		state.app.ottMode === true &&
		(state.stores[state.app.storeId]
			? state.stores[state.app.storeId].ottMode === true
			: false)

	return enabled
}

export const selectOTTIndex = (state: RootState) => state.app.ottIndex

export const selectRunOTT = (state: RootState) =>
	state.app.runOTT && state.app.isAppGuideActive

export const selectIsAppGuideActive = (state: RootState) =>
	state.app.isAppGuideActive

export const selectOTTStepsSelected = (state: RootState) =>
	state.app.ottStepsSelected

export const selectIsQueueAvailable = (state: RootState) =>
	state.app.queueAvailable

export const selectIsDebugMode = (state: RootState) => state.app.isDebugMode
